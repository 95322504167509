import Listing from '@/models/Listing'
import Api from '@/services/api'
import { each, find } from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1,
    },
    listingDetails: null,
  },

  mutations: {
    setListings(state, listings) {
      each(listings, (listing) => {
        const exist = find(state.list, { id: listing.id })
        if (!exist) {
          state.list.push(new Listing(listing))
        }
      })
    },

    setListingDetails(state, listing) {
      if (listing instanceof Listing) {
        state.listingDetails = listing
      } else {
        state.listingDetails = new Listing(listing)
      }
    },

    setListMeta(state, meta) {
      state.listMeta = meta
    },

    clearListings(state) {
      state.list = []
    },
    clearListingDetails(state) {
      state.listingDetails = null
    },
  },

  actions: {
    async getListings({ commit }, params) {
      const query = Listing.page(params.page || 1).orderBy(params.sort)

      if (params.filter) {
        query.where(params.filter.field, params.filter.search)
      }

      if (params.include) {
        query.include(params.include)
      }

      const res = await query.params({ limit: 20 }).get()

      commit('setListings', res.data)
      commit('setListMeta', res.meta)
    },

    async getListingDetails({ commit }, id) {
      const { data } = await Api.get(`admin/listings/${id}`)
      commit('setListingDetails', data.data)
    },

    async updateListing({ commit }, formData) {
      const { data } = await Api.post(`admin/listings/${formData.id}`, formData)
      commit('setListingDetails', data.data)
    },

    async disableListing({ commit }, { id, action, reason }) {
      const { data } = await Api.post(`admin/listings/${id}/${action}`, {
        reason,
      })
      commit('setListingDetails', data.data)
    },
  },
}
