import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import user from './modules/user'
import notification from './modules/notification'
import report from './modules/report'
import versionControl from './modules/version-control'
import category from './modules/category'
import listing from './modules/listing'
import booking from './modules/booking'
import review from './modules/review'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    user,
    notification,
    report,
    versionControl,
    category,
    listing,
    booking,
    review,
  },
  state: {
    controls: {
      showDrawer: true,
    },
    snackbar: {
      message: '',
      show: false,
      color: 'success',
    },
  },
  mutations: {
    toggleDrawer(state, value) {
      state.controls.showDrawer = value
    },
    setSnackbar(state, value) {
      state.snackbar = value
    },
  },
  actions: {
    showSnackbar({ commit }, { message, color }) {
      commit('setSnackbar', {
        message,
        color,
        show: true,
      })
    },
    hideSnackbar({ commit }) {
      commit('setSnackbar', {
        message: '',
        color: 'success',
        show: false,
      })
    },
  },
})
