import Model from './Model'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

export default class Listing extends Model {
  resource() {
    return 'admin/bookings'
  }

  get addedDate() {
    return dayjs.utc(this.created_at).local().format('MMM DD, YYYY hh:mm A')
  }

  get startDate() {
    return dayjs.utc(this.start_date).local().format('MMM DD, YYYY')
  }

  get endDate() {
    return dayjs.utc(this.end_date).local().format('MMM DD, YYYY')
  }

  get cancelledAt() {
    return dayjs.utc(this.cancelled_at).local().format('MMM DD, YYYY')
  }

  get returnedDate() {
    if (!this.item_returned_at) {
      return null
    }

    return dayjs
      .utc(this.item_returned_at)
      .local()
      .format('MMM DD, YYYY hh:mm A')
  }
}
