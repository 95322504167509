import Api from '@/services/api'
import { map } from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1,
    },
  },

  mutations: {
    setList(state, records) {
      // each(records, (record) => {
      //   const exist = find(state.list, { id: record.id })
      //   if (!exist) {
      //     state.list.push(record)
      //   }
      // })

      state.list = map(records, (record) => {
        return record
      })
    },

    setListMeta(state, meta) {
      state.listMeta = meta
    },

    clearList(state) {
      state.list = []
    },
  },

  actions: {
    async getRecords({ commit }, params) {
      const res = await Api.get(`listings/${params.id}/reviews`, {
        params: params,
      })

      commit('setList', res.data.data)
      commit('setListMeta', res.data.meta)
    },
  },
}
