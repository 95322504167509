import Booking from '@/models/Booking'
import Api from '@/services/api'
import { each, find, map } from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1,
    },
    lateReturnPaymentList: [],
    lateReturnPaymentListMeta: {
      current_page: 0,
      last_page: 1,
    },
    recordDetails: null,
  },

  mutations: {
    setLateReturnPaymentList(state, lateReturnPayments) {
      state.lateReturnPaymentList = map(lateReturnPayments, (payment) => {
        return payment
      })
    },

    setLateReturnPaymentListMeta(state, meta) {
      state.lateReturnPaymentListMeta = meta
    },

    setList(state, records) {
      each(records, (record) => {
        const exist = find(state.list, { id: record.id })
        if (!exist) {
          state.list.push(new Booking(record))
        }
      })
    },

    setRecordDetails(state, record) {
      if (record instanceof Booking) {
        state.recordDetails = record
      } else {
        state.recordDetails = new Booking(record)
      }
    },

    setListMeta(state, meta) {
      state.listMeta = meta
    },

    clearList(state) {
      state.list = []
    },
    clearRecordDetails(state) {
      state.recordDetails = null
    },
  },

  actions: {
    async getRecords({ commit }, params) {
      const query = Booking.page(params.page || 1).orderBy(params.sort)

      if (params.filter) {
        query.where(params.filter.field, params.filter.search)
      }

      if (params.include) {
        query.include(params.include)
      }

      const res = await query.params({ limit: 20 }).get()

      commit('setList', res.data)
      commit('setListMeta', res.meta)
    },

    async getRecordDetails({ commit }, id) {
      const { data } = await Api.get(`admin/bookings/${id}`)
      commit('setRecordDetails', data.data)
    },

    // async updateListing({ commit }, formData) {
    //   const { data } = await Api.post(`admin/listings/${formData.id}`, formData)
    //   commit('setRecordDetails', data.data)
    // },

    // async disableListing({ commit }, { id, action, reason }) {
    //   const { data } = await Api.post(`admin/listings/${id}/${action}`, {
    //     reason,
    //   })
    //   commit('setRecordDetails', data.data)
    // },

    async getBookingLateReturnPayments({ commit }, params) {
      const { data } = await Api.get(
        `admin/bookings/${params.booking_id}/lateReturnPayments`,
        { params: params }
      )

      commit('setLateReturnPaymentList', data.data)
      commit('setLateReturnPaymentListMeta', data.meta)
    },
  },
}
