import Category from '@/models/Category'
import Api from '@/services/api'
import { each, find } from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    categoryDetails: null,
  },

  mutations: {
    setCategoryList(state, categories) {
      each(categories, (category) => {
        const exist = find(state.list, { id: category.id })
        if (!exist) {
          state.list.push(new Category(category))
        }
      })
    },

    setCategoryDetails(state, category) {
      if (category instanceof Category) {
        state.categoryDetails = category
      } else {
        state.categoryDetails = new Category(category)
      }
    },

    clearCategoryList(state) {
      state.list = []
    },
    clearCategoryDetails(state) {
      state.categoryDetails = null
    },
  },

  actions: {
    async getCategories({ commit }, params) {
      const query = Category.include('image', 'subcategories').orderBy(
        params.sort
      )

      if (params.search) {
        query.where('search', params.search)
      }

      const res = await query.params({ limit: 20 }).get()

      commit('setCategoryList', res.data)
    },

    async getCategoryDetails({ commit }, id) {
      const { data } = await Api.get(
        `admin/categories/${id}?include=image,subcategories`
      )
      commit('setCategoryDetails', data.data)
    },

    async updateCategory({ commit }, formData) {
      if (!formData.image) {
        delete formData.image
      }
      const { data } = await Api.put(
        `admin/categories/${formData.id}`,
        formData
      )
      commit('setCategoryDetails', data.data)
    },

    async createCategory({ commit }, formData) {
      if (!formData.image) {
        delete formData.image
      }
      const { data } = await Api.post(`admin/categories`, formData)

      return data.data
    },
  },
}
